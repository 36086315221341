/*
Объектная модель документа
+ window
+ Навигация
+ Выборка элементов
+ Изменение атрибутов, classList, style
- Создание, удаление и перемещение элементов
+- alert, prompt, confirm
+ console
- cookie, localStorage, sessionStorage
- debugger
+ события, объект события
*/
import DOMIntro from './DOMIntro'
import T01 from './T01'
import Video from '@/components/task-templates/Video'

const videoPath = name => `${process.env.BASE_URL}static/js-dom/${name}.mp4`

export default [
  {
    component: DOMIntro,
    label: '!'
  },
  {
    component: T01
  },
  {
    component: Video,
    name: 'Спойлер',
    text: '<p>Назвать 02-spoiler.html</p>',
    video: videoPath('spoiler')
  },
  {
    component: Video,
    name: 'Табы',
    text: '<p>Назвать 03-tabs.html</p>',
    video: videoPath('tabs')
  },
  {
    component: Video,
    name: 'Модальное окно',
    text: '<p>Назвать 04-modal.html</p>',
    video: videoPath('modal')
  },
  {
    component: Video,
    name: 'Простая галерея',
    text: '<p>Назвать 05-gallery.html</p>',
    video: videoPath('gallery')
  },
  {
    component: Video,
    name: 'Drag & Drop',
    text: '<p>Назвать 06-drag-drop.html</p>',
    video: videoPath('drag-drop')
  },
  {
    component: Video,
    name: 'Todo-лист',
    text: `
      <p>Назвать 07-todo.html</p>
      <p><a href="https://8-ball.ru/todo" class="link" target="_blank">Образец</a></p>
      <p>Обратите внимание, что после перезагрузки страницы все данные сохраняются. Для этого необходимо использовать <a href="https://learn.javascript.ru/localstorage" target="_blank" class="link">localStorage</p>
    `,
    video: videoPath('todo')
  }
]
// + спойлер (текст с открывашкой)
// + табы
// + всплывающее окно
// + слайдер без анимации
// + drag&drop
// + todo-лист

// - корзина товаров
// - калькулятор корзины

// • фильтры контента
// • слайдер c анимацией

// • проект с подсчётом часов в неделю
// • выбор с подсветкой по очереди
// • арифметический тренажёр
// • помощник тренера (как повесить вес + программа тренировок с планом + таймер со звуком + авторизация)
// * rangebar

// + валидация
// • свой select
