<template>
  <h2>DOM-дерево</h2>
  <p>Тут придётся достаточно много прочитать</p>
  <p><a href="https://learn.javascript.ru/document" class="link" target="_blank">Документ</a></p>
  <p><a href="https://learn.javascript.ru/events" class="link" target="_blank">Введение в события</a></p>
  <p><a href="https://learn.javascript.ru/event-details" class="link" target="_blank">Интерфейсные события</a></p>
  <p>Для выполнения заданий этой темы создайте в проекте папку "17-dom", внутри неё для каждого задания нужно будет создавать отдельный html-файл. Как называть файлы будет написано в задании.</p>
</template>

<script>
export default {
  props: ['task']
}
</script>
