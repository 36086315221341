<template>
  <h2 class="mt-0">Разминка</h2>
  <p>Скачайте файл <a :href="fileUrl" download>01-warm-up.html</a>. Положите его в папку 17-dom. Откройте файл и выполните задания, описанные в нём, изменяя код самого файла. Под каждым блоком с заданием нужно вставить тег script, и внутри писать решение в фигурных скобках, как сделано в первой задаче.</p>
</template>

<script>
export default {
  props: ['task'],
  setup() {
    return {
      fileUrl: `${process.env.BASE_URL}static/js-dom/01-warm-up.html`
    }
  }
}
</script>

<style scoped>
.link {
  text-decoration: underline;
}
</style>
