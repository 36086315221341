<template>
  <h2 class="m0 mt-medium">{{ task.name }}</h2>
  <div v-html="task.text" class="mt-medium" />
  <div class="mt-medium">
    <video :src="task.video" class="video" controls preload="metadata" />
  </div>
</template>

<script>

export default {
  props: ['task']
}
</script>

<style scoped>
.video {
  height: 300px;
  border: 1px solid black;
}
</style>
